<template>
  <div>
    <div class="title">{{ $t("adnavPage.AssetsManagement") }}</div>
    <el-tabs v-show="false" v-model="tabsValue" @tab-click="tabsClick">
      <el-tab-pane :label="$t('titleI18n.AccountsReceivable')" name="1"></el-tab-pane>
      <el-tab-pane :label="$t('titleI18n.WarehouseReceipt')" name="2" disabled></el-tab-pane>
    </el-tabs>
    <el-col v-show="tabsValue === '1'" :span="24" class="flex-between">
      <el-form ref="form" :model="formSearch" >
        <el-form-item class="flex-row" :label="$t('columnI18n.Status')">
          <el-select v-model="optionsValStatus" @change="changeStatus">
            <el-option
                v-for="item in optionsStatus"
                :key="item.valueNew"
                :label="$t(item.label)"
                :value="item.valueNew">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
    <el-table :data="tableAssets">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
      </template>
      <el-table-column :label="$t('tableI18n.OrderCode')" prop="id" align="center" width="200"></el-table-column>
      <el-table-column :label="$t('tableI18n.CoreUserCompanyName')" prop="paymentName" align="center"></el-table-column>
      <el-table-column :label="$t('tableI18n.SupplierName')" prop="username" align="center"></el-table-column>
      <el-table-column prop="amount" align="center" width="160">
        <template slot="header">
          {{ $t('tableI18n.OrderAmount') }}<br/>{{ $t('unitI18n.MillionUsDollars') }}
        </template>
        <template v-slot="scope">
          {{ ((Number(scope.row.amount || 0) / $enums.UNIT_MILLION).toFixed(2) || 0) | toThousandFilterTwo }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('tableI18n.OrderDate')" prop="dueDate" align="center">
        <template v-slot="scope">
          {{ scope.row.dueDate | timeStampToDate }}
        </template>
      </el-table-column>
      <el-table-column prop="expiryDays" align="center">
        <template slot="header">
          {{ $t('tableI18n.OrderDays') }} {{ $t('unitI18n.DaysParentheses') }}
        </template>
        <template v-slot="scope">
          <span v-if="scope.row.status === optionsStatus[3].valueNew || scope.row.status === optionsStatus[4].valueNew"> -- </span>
          <span v-else :class="{'text-red' : Number(scope.row.expiryDays) < 1}">{{ scope.row.expiryDays }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('tableI18n.Status')" prop="status" align="center" width="100">
        <template v-slot="scope">
          {{ scope.row.status | filterAssetsStatus }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('tableI18n.Operation')" align="center">
        <template v-slot="scope">
          <el-link class="list-opt" :underline="false" type="primary" @click="toDetail('/assets-list/assets-info', scope.row.id)">{{ $t("btnI18n.See") }}</el-link>
        </template>
      </el-table-column>
    </el-table>
    <AdPagination :currentPage="tablePageCP" :pageSize="tablePagePS" :pageTotal="tableTotal" @handlePage="handlePage"></AdPagination>
  </div>
</template>

<script>
import Storage from "@/utils/storage";
import AdPagination from "@/components/ad-pagination";

export default {
  name: "assets-list",
  components: {
    AdPagination
  },
  data() {
    return {
      tableLoading: false,
      tabsValue: '1',
      formSearch: {},
      optionsStatus: this.$enums.ASSETS_STATUS,
      optionsValStatus: '',
      tableAssets: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
    };
  },
  created() {
    this.getAssetsList();
  },
  methods: {
    changeStatus() {
      Storage.setSessionItem("assetsStatus", this.optionsValStatus);
      this.getAssetsList();
    },
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getAssetsList();
    },
    getAssetsList() {
      let _this = this;
      this.tableLoading = true;
      // FINANCING, APPLYING, PASS, REFUSE, EXPIRED
      let params = { status: this.optionsValStatus, page: this.tablePageCP, pageSize: this.tablePagePS };
      if (!this.optionsValStatus) delete params.status;
      this.$axios.get('/manage-supplier/receivables', { params: params }).then((response) => {
        _this.tableAssets = response.data.rows || [];
        _this.tableTotal = response.data.count || 0;
        _this.tableLoading = false;
      }).catch(error => {
        console.log(error);
        _this.tableLoading = false;
      });
    },
    tabsClick() {},
    toDetail(url, id) {
      this.$router.push({ path: url, query: { id: id } });
    }
  }
};
</script>

<style scoped lang="scss">
.list-opt {
  margin: 0 5px;
}
</style>
